import {Modal, Col, Row, Form} from 'react-bootstrap'
import React, { useContext, useEffect } from 'react'
import {useState} from 'react'
import Alert from 'react-bootstrap/Alert'
import { toast } from 'react-toastify'
import { UserContext } from '../../UseContext'
import { validateFormFields } from '../../utils'
import MaskedInput from 'react-text-mask'
import {FormInput} from '../../components/formElements/FormInput'
import CodeMaintenanceService from '../../services/CodeMaintenanceService'
import { Typeahead } from 'react-bootstrap-typeahead'
import IssuerMaintenanceService from '../../services/IssuerMaintenanceService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import CommonService from '../../services/CommonService'
import AddContact from './addContact'
import swal from 'sweetalert'
import DataGrid from '../GenericComponents/DataGrid'
import { Column } from 'primereact/column'

const EditContact = (props) => {
    const user = useContext(UserContext);
    const {onModalClose, contactInfo} = props;
    const [formErrors, setFormErrors] = useState({});
    const [count, setCount] = useState(0);
    const [bgcolor, setBgcolor] = useState('yellowgreen');
    const [contact, setContact] = useState({...contactInfo});
    const handleContactChange = e => {
        const {name} = e.target;
        let value = e.target.value;
        if(name === 'contactCityName') {
            value = value.replace(/[^A-Za-z\s]/ig, '');
        }
        if(name === 'contactStateCode') {
            value = value.replace(/[^A-Za-z]/ig, '');
        }
        if(name === 'contactPhoneExtn') {
            value = value.replace(/[^\d]/g, "").substr(0, 10);
        }
        if(name === 'contactZipCode') {
            value = value.replace(/[^\d{5}-]/g, "").substr(0, 10);
        }
        if(name === 'activeInd') {
            value = e.target.checked ? 'Y' : 'N';
        }

        setContact(prevState => ({
            ...prevState, [name] : value
        }))
    }
    const handleCommentValue = (e, key) => {
        setCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setBgcolor(color);
        setContact(prevState => ({
            ...prevState, [key] : e.target.value
        }))
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target.closest('form#edit-contact');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {  
            setFormErrors({});
            var obj = {
                msgId: null,
                sysId: null,
                opr: "putContact",
                hdrStruct: null,
                queryParam: null,
                reqData: { 
                    "contact" : {
                        "contactId": contact.contactId,
                        "activeInd": contact.activeInd,
                        "contactAddressLine1": contact.contactAddressLine1,
                        "contactAddressLine2": contact.contactAddressLine2,
                        "contactAddressLine3": contact.contactAddressLine3,
                        "contactAddressLine4": contact.contactAddressLine4,
                        "contactCityName": contact.contactCityName,
                        "contactStateCode": contact.contactStateCode,
                        "contactZipCode": contact.contactZipCode,
                        "contactFaxAreaCode": contact.contactFaxAreaCode,
                        "contactFaxNumber": contact.contactFaxNumber,
                        "contactEmailId": contact.contactEmailId !== "" ? contact.contactEmailId : null,
                        "contactFirstName": contact.contactFirstName,
                        "contactLastName": contact.contactLastName,
                        "contactMiddleName": contact.contactMiddleName,
                        "contactPhoneAreaCode": contact.contactPhoneAreaCode,
                        "contactPhoneExtn": contact.contactPhoneExtn,
                        "contactPhoneNumber": contact.contactPhoneNumber,
                        "contactComment": contact.contactComment,
                        "orgAddressInd": contact.orgAddressInd,
                        "isUserInAzureB2C" : contact.isUserInAzureB2C,
                        "lastUpdateUser": CommonService.getUserName(user)
                    }
                },
            };
        
            IssuerMaintenanceService.updateContact(obj)
                .then((result) => {
                    if (result.data.errors && result.data.errors.length > 0){
                        if(result.data.errors[0].code === '200'){
                            swal(result.data.errors[0].message, {
                                buttons: ["No", "Yes"],
                            }).then((value) => {
                               if(value){
                                   createB2CUserProfile()
                               } else{
                                toast.success("Contact updated successfully!")
                                onModalClose();
                                }
                            });
                        } else {
                            toast.error(result.data.errors[0].message)
                        }
                    }
                    else{
                        toast.success("Contact updated successfully!");
                        onModalClose();
                    }
                    
                });
        }
    }

    const createB2CUserProfile = () => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "createB2CUserProfile",
            hdrStruct: null,
            queryParam: null,
            reqData: { 
                "contact" : contact,
            },
        };

        IssuerMaintenanceService.createB2CUserProfile(obj)
        .then((result) => {
            if (result.data.errors && result.data.errors.length > 0){
                toast.error(result.data.errors[0].message)
            }
            else{
                toast.success("Contact updated successfully!");
                onModalClose();
            }
        });
    }

    return (
        <Modal show={true} backdrop="static" onHide={onModalClose}>
            <Modal.Header closeButton><h3>Edit Contact</h3></Modal.Header>
            <Modal.Body>
            <form id="edit-contact">
                <div className="contactForm">
                    <Row>
                        <Col md={6} className="control">
                        <FormInput type="text" label="Contact First Name" value={contact.contactFirstName} name="contactFirstName" isrequired="true"  validations={["required"]} onChange={(e)=>handleContactChange(e)}/>
                            {formErrors['contactFirstName'] && <span className="error">{formErrors['contactFirstName']}</span>}

                        </Col>
                        <Col md={6} className="control">
                            <label>Contact Middle Name:</label>
                            <input type="text" name="contactMiddleName"value={contact.contactMiddleName} onChange={(e)=>handleContactChange(e)}/>
                        </Col>
                        <Col md={6} className="control">
                        <FormInput type="text" label="Contact Last Name" value={contact.contactLastName} name="contactLastName" isrequired="true"  validations={["required"]} onChange={(e)=>handleContactChange(e)}/>
                            {formErrors['contactLastName'] && <span className="error">{formErrors['contactLastName']}</span>}
                        </Col>
                        <div>
                            <Form.Check inline type="checkbox" id="orgAddressInd" name="orgAddressInd" label="Primary Contact?" checked={contact.orgAddressInd === 'Y'} onChange={(e)=>handleContactChange(e)} />
                        </div>
                    </Row>
                    <Row>                       
                        <Col md={6} className="control">
                            <FormInput type="text" label="Address Line1" name="contactAddressLine1" isrequired="true"  
                            validations={["required"]} value={contact.contactAddressLine1}
                            onChange={(e)=>handleContactChange(e)}
                            />
                            {formErrors['contactAddressLine1'] && <span className="error">{formErrors['contactAddressLine1']}</span>}
                        </Col>
                        <Col md={6} className="control">
                            <label>Address Line2:</label>
                            <input type="text" name="contactAddressLine2" value={contact.contactAddressLine2}
                            onChange={(e)=>handleContactChange(e)}
                            />
                        </Col>
                        <Col md={6} className="control">
                            <label>Address Line3:</label>
                            <input type="text" name="contactAddressLine3"  value={contact.contactAddressLine3} 
                            onChange={(e)=>handleContactChange(e)}
                            />
                        </Col>
                        <Col md={6} className="control">
                            <label>Address Line4:</label>
                            <input type="text" name="contactAddressLine4"   value={contact.contactAddressLine4}
                            onChange={(e)=>handleContactChange(e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className="control">
                            <FormInput type="text" label="City" name="contactCityName" isrequired="true" 
                            validations={["required"]} 
                            maxLength="50"
                            value={contact.contactCityName}
                            onChange={(e)=>handleContactChange(e)}
                            />
                            {formErrors['contactCityName'] && <span className="error">{formErrors['contactCityName']}</span>}
                        </Col>
                        <Col md={4} className="control">
                            <FormInput type="text" label="State" name="contactStateCode" isrequired="true"  
                            validations={["required", "minLength:2"]} maxLength="2" 
                            value={contact.contactStateCode}
                            onChange={(e)=>handleContactChange(e)}
                            />
                            {formErrors['contactStateCode'] && <span className="error">{formErrors['contactStateCode']}</span>}
                        </Col>
                        <Col md={4} className="control">
                            <FormInput type="text" label="Zip Code" name="contactZipCode" isrequired="true"  
                            validations={["required","zipCode"]} 
                            value={contact.contactZipCode}
                            onChange={(e)=>handleContactChange(e)}
                            />
                            {formErrors['contactZipCode'] && <span className="error">{formErrors['contactZipCode']}</span>}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className="control">
                            <label><span className="required">*</span>Area Code (XXX)</label>
                            <MaskedInput mask={[/\d/, /\d/, /\d/]} 
                                guide={true}
                                name="contactPhoneAreaCode"
                                data-validations={["required", "telNo:3"]}
                                value={contact.contactPhoneAreaCode}
                                onChange={(e)=>handleContactChange(e)}
                                onBlur={(e)=>handleContactChange(e)}
                            />
                            {formErrors['contactPhoneAreaCode'] && <span className="error">{formErrors['contactPhoneAreaCode']}</span>}
                        </Col>
                        <Col md={4} className="control">
                            <label><span className="required">*</span>Phone Number (XXX-XXXX)</label>
                            <MaskedInput mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} 
                                guide={true}
                                name="contactPhoneNumber"
                                value={contact.contactPhoneNumber}
                                data-validations={["required", "telNo:8"]}
                                onChange={(e)=>handleContactChange(e)}
                                
                            />
                            {formErrors['contactPhoneNumber'] && <span className="error">{formErrors['contactPhoneNumber']}</span>}
                        </Col>
                        <Col md={4} className="control">
                            <label>Ext</label>
                            <input type="text" name="contactPhoneExtn" value={contact.contactPhoneExtn}
                            onChange={(e)=>handleContactChange(e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className="control">
                            <label>Fax Area Code (XXX)</label>
                            <MaskedInput mask={[/\d/, /\d/, /\d/]} 
                                guide={true}
                                name="contactFaxAreaCode"
                                value={contact.contactFaxAreaCode} 
                                data-validations={["telNo:3"]}
                                onChange={(e)=>handleContactChange(e)}
                                onBlur={(e)=>handleContactChange(e)}
                                
                            />
                            {formErrors['contactFaxAreaCode'] && <span className="error">{formErrors['contactFaxAreaCode']}</span>}
                        </Col>
                        <Col md={4} className="control">
                            <label>Fax Number (XXX-XXXX)</label>
                            <MaskedInput mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} 
                                guide={true}
                                name="contactFaxNumber"
                                data-validations={["telNo:8"]}
                                value={contact.contactFaxNumber}
                                onChange={(e)=>handleContactChange(e)}
                                
                            />
                            {formErrors['contactFaxNumber'] && <span className="error">{formErrors['contactFaxNumber']}</span>}
                        </Col>
                        <Col md={4} className="control">
                            <FormInput type="email" name="contactEmailId" label="E-Mail"  
                                value={contact.contactEmailId}
                                validations={["email"]}
                                onChange={(e)=>handleContactChange(e)} 
                                disabled={contact.isUserInAzureB2C}
                                />
                            {formErrors['contactEmailId'] && <span className="error">{formErrors['contactEmailId']}</span>}
                        </Col>
                    </Row> 
                    <div>
                        <Form.Check inline type="checkbox" id="activeInd" name="activeInd" label="Is Active?" checked={contact.activeInd === 'Y'} onChange={(e)=>handleContactChange(e)} />
                    </div>
                    <Row>
                    <div>
                        <label>Comment: </label>
                        <div className="comments-box">
                            <textarea name="contactComment" value={contact.contactComment}
                            id="comments-input" onChange={(e)=>handleCommentValue(e,'contactComment')}
                            className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..."/>
                            <span style={{position: 'relative', left: '45%' , backgroundColor: bgcolor }}>{count} out of 4000 max</span>
                        </div>
                    </div>
                    </Row>
                </div>
                <div className="btn-div">
                <div className="fright">
                    <button type="button" className="custom-button-secondary  btn-secondary" onClick={onModalClose}>Cancel</button>{" "}
                    <button className="custom-button" type="button" onClick={(e)=>handleSubmit(e)}>Update Contact</button>
                    </div>
                </div>
            </form>                
            </Modal.Body>
        </Modal>
    )
}
const EditFiler = (props) => {
    const user = useContext(UserContext);
    const {onModalClose, orgId, orgType} = props;
    const [formErrors, setFormErrors] = useState({});
    const [orgTypes, setOrgTypes] = useState([]);
    const [orgDivisions, setOrgDivisions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [issuerContcts, setIssuerContacts] = useState([]);
    const [filer, setFiler] = useState([]);
    const [errorMessage, setErrorMessage] = useState();
    const [selectedContactInfo, setSelectedContactInfo] = useState(null);
    const [editContact, setEditContact] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const [count, setCount] = useState(0);
    const [bgcolor, setBgcolor] = useState('yellowgreen');
    const [addContact, setAddContact] = useState(false);

    useEffect(()=>{
       async function getFilerByOrgId() {
            var obj = {
                msgId: null,
                sysId: null,
                opr: "getFilerByOrgId",
                hdrStruct: null,
                queryParam: null,
                reqData: { 
                    "orgId" : orgId
                },
            };
          
              await IssuerMaintenanceService.getIssuer(obj)
                .then((res) => res)
                .then((result) => {
                  Object.keys(result.data.responses).forEach((key) => {
                    setFiler(result.data.responses[key]);
                  });
                });
         }
        async function fetchOrgTypes() {
            var obj = {
                msgId: "null",
                sysId: null,
                opr: "GetLookups",
                hdrStruct: null,
                queryParam: null,
                reqData: { cdiacCode: "ORGT", active: "Y" },
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
            .then((res) => res)
            .then((result) => {
                const filteredList = result.data.responses[0].filter(el => el.activeInd === "Y");
                const orgTypesListNew = [];
                filteredList.forEach((orgType, i)=>{
                    let obj = {};
                    obj = orgType;
                    obj.value = orgType.cdiacCodeValueId;
                    obj.label = orgType.cdiacCodeValueDesc;
                    orgTypesListNew.push(obj);
                })
                
                setOrgTypes(orgTypesListNew);
                setIsLoading(false);
            })
        }

        async function fetchOrgDivisions() {
            var obj = {
                msgId: "null",
                sysId: null,
                opr: "GetLookups",
                hdrStruct: null,
                queryParam: null,
                reqData: { cdiacCode: "ORGD" },
            };
        
            await CodeMaintenanceService.getAllCodes(obj)
            .then((res) => res)
            .then((result) => {
                setOrgDivisions(result.data.responses[0]);
            })
        }


        getFilerByOrgId();
        fetchOrgTypes();
        fetchOrgDivisions();
        getIssuerContactsByIssuerId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    const getIssuerContactsByIssuerId = async() => {
        var obj = {
            msgId: null,
            sysId: null,
            opr: "getContactsByOrgId",
            hdrStruct: null,
            queryParam: null,
            reqData: { 
                "orgId" : orgId
            },
          };
      
          await IssuerMaintenanceService.getIssuerContacts(obj)
            .then((res) => res)
            .then((result) => {
              Object.keys(result.data.responses).forEach((key) => {
                  setIssuerContacts(result.data.responses[key]);
              });
              setIsLoading(false);
            });
    }

    const handleCommentValue = (e, key) => {
        setCount(e.target.value.length);
        const color = (e.target.value.length === 4000) ? 'orangered' : 'yellowgreen';
        setBgcolor(color);
        setFiler(prevState => ({
            ...prevState, [key] : e.target.value
        }))
    };


    const handleOrgChange = (e, key) => {
        const val = e.length ? e[0].cdiacCodeValueId : "";

        setFiler(prevState => ({
            ...prevState, [key] : val
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target.closest('form#edit-issuer');
        let formErrors = validateFormFields(form.elements);
        if(Object.keys(formErrors).length > 0) {
            setFormErrors(formErrors);
        } else {  
            setFormErrors({});
            var obj = {
                msgId: null,
                sysId: null,
                opr: "putOrganization",
                hdrStruct: null,
                queryParam: null,
                reqData: { 
                    "orgId" : orgId,
                    "organization" : {...filer, 
                        "orgTypeId": filer.orgTypeId,
                        "orgDivisionId": filer.orgDivisionId,
                        "orgComment": filer.orgComment,
                        "lastUpdateUser": CommonService.getUserName(user)
                    }
                },
            };
        
            IssuerMaintenanceService.updateOrganization(obj)
                .then((result) => {
                    if (result.data.errors && result.data.errors.length > 0){
                        setErrorMessage(result.data.errors[0].message)
                    }
                    else{
                        toast.success("Organization updated successfully!");
                        onModalClose();
                    }
                    
                });
        }
    }

    const viewEditFormatter = (row) => {
        return (
          <div style={{
            textAlign: "center",
            lineHeight: "normal"
          }}>
            <FontAwesomeIcon icon={faPencilAlt} className="custom-icon" onClick={(e)=>onRowClick(e, row)}/> 
          </div>
        );
      }
    const onRowClick = (e, row) => {
        e.preventDefault();
        setSelectedContactInfo(row);
        setEditContact(true);
        setShowModal(false);
    }

    const handleContatModalClose = () => {
        setEditContact(false);
        setShowModal(true);
        getIssuerContactsByIssuerId();
    }
    const statusFormatter = (row) => {
        return (
          <div style={{ textAlign: "center" }}>
          {row['activeInd'] === "Y" ? "Yes" : "No"}
          </div>
        );
    }
    const issuerContactColumns = [{
        dataField: 'contactFirstName',
        text: 'Contact First Name',
        sort: true
      }, {
        dataField: 'contactLastName',
        text: 'Contact Last Name',
        sort: true
      },{
        dataField: 'contactEmailId',
        text: 'Contact Email',
        sort: true
      },{
        dataField: 'activeInd',
        text: 'Is Active?',
        sort: true,
        formatter: statusFormatter
      },{
        dataField: "",
        text: "Action",
        formatter: viewEditFormatter
      }
      ];

    const handleAddContact = (e) => {
        e.preventDefault();
        setAddContact(true);
        setShowModal(false);
    }
    const handleAddContatModalClose = () => {
        setAddContact(false);
        setShowModal(true);
        getIssuerContactsByIssuerId();
    }
    return (
        <>
        <Modal show={showModal} backdrop="static" onHide={onModalClose}>
            <Modal.Header closeButton><h3>Edit Filer</h3></Modal.Header>
            <Modal.Body>
            {errorMessage && <Alert variant="danger">
                        <p>
                            {errorMessage}
                        </p>
              </Alert>}
                <div>
                    <form id="edit-issuer">
                    <Row>
                        <Col md={6} className="control">
                        <FormInput 
                            type="text" 
                            name="orgName" 
                            label="Organization Name"
                            value={filer.orgName}
                            isrequired="true"
                            validations={["required"]}
                            maxLength="120"
                            disabled="true"
                        />
                        {formErrors['orgName'] && <span className="error">{formErrors['orgName']}</span>}
                        </Col>
                        
                        <Col md={6}>
                            <label><span className="required">*</span>Organization Type</label>
                            <Typeahead 
                                id="orgTypeId"
                                name="orgTypeId"
                                placeholder="Choose Org Type..."
                                labelKey="cdiacCodeValueDesc"
                                selected = {
                                    orgTypes.filter(option => 
                                        option.cdiacCodeValueId === filer.orgTypeId)
                                    }
                                onChange={(e)=>handleOrgChange(e, 'orgTypeId')}
                                options={orgTypes}
                                inputProps={{ 'data-validations': 'required', name: 'orgTypeId' }}
                                isLoading={isLoading}
                                />
                        {formErrors['orgTypeId'] && <span className="error">{formErrors['orgTypeId']}</span>}
                        </Col>
                    
                        <Col md={6}>
                            <label><span className="required">*</span>Division</label>
                            <Typeahead 
                                id="orgDivisionId"
                                name="orgDivisionId"
                                placeholder="Choose Org Division..."
                                labelKey="cdiacCodeValueDesc"
                                selected = {
                                    orgDivisions.filter(option => 
                                        option.cdiacCodeValueId === filer.orgDivisionId)
                                    }
                                onChange={(e)=>handleOrgChange(e, 'orgDivisionId')}
                                options={orgDivisions}
                                inputProps={{ 'data-validations': 'required', name: 'orgDivisionId' }}
                                />
                        {formErrors['orgDivisionId'] && <span className="error">{formErrors['orgDivisionId']}</span>}
                        </Col>
                    </Row>
                    <Row>
                    <div>
                        <label>Comment: </label>
                        <div className="comments-box">
                            <textarea name="orgComment" value={filer.orgComment}
                            id="issuer-comments" onChange={(e)=>handleCommentValue(e,'orgComment')}
                            className="form-control" rows="5" maxLength="4000" placeholder="Add a comment..."/>
                            <span style={{position: 'relative', left: '45%' , backgroundColor: bgcolor }}>{count} out of 4000 max</span>
                        </div>
                    </div>
                    </Row>
                    <div className="btn-div">
                        <div className="fright">
                        <button type="button" className="custom-button-secondary  btn-secondary" onClick={onModalClose}>Cancel</button>{" "}
                        <button className="custom-button" type="button" onClick={(e)=>handleSubmit(e)}>Save</button>
                        </div>
                    </div>
                    </form>
                    <hr/>
                    <Row>
                        <Col className="control">
                        <p className="fleft"><b>Contacts:</b></p>
                        <button className="fright custom-button" type="button" data-dismiss="modal" onClick={(e)=>handleAddContact(e)}>Create Contact</button>
                        </Col>
                        <DataGrid
                        dataSource={issuerContcts}
                        sortField='contactFirstName'
                        emptyDataMessage='No data available to display'
                        showPaginator={false}
                        showQuickSearch={false}
                        >
                        {issuerContactColumns.map((column, i) => {
                            return (
                            <Column 
                            key={i}
                            field={column.dataField}
                            header={column.text}
                            sortable={column.sort} 
                            body={column.formatter}
                            hidden={column.hidden}
                            />)
                            }) 
                        } 
                        </DataGrid>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
        {editContact && <EditContact contactInfo={selectedContactInfo} onModalClose={()=>handleContatModalClose()} orgType={orgType}/>}
        {addContact && <AddContact onModalClose={()=>handleAddContatModalClose()} orgId={orgId} orgType={orgType}/>}
        </>
    )
}

export default EditFiler
